$xxs-max: 339px;
$xxs-min: 340px;

$xs-max: 767px;
$xs-min: 768px;

$sm-max: 991px;
$sm-min: 992px;

$md-max: 1199px;
$md-min: 1200px;

$lg-max: 1599px;
$lg-min: 1600px;

@mixin desktop-large {
  @media (max-width: $lg-max) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $md-max) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $md-max) and (min-height: 500px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: 320px) and (max-width: $xs-max) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: $xs-min) {
    @content;
  }
}

@mixin only-tablet {
  @media screen and (max-width: $md-max) and (min-width: $xs-min) and (min-height: 500px) {
    @content;
  }
}

@mixin only-desktop {
  @media screen and (min-width: $md-min) {
    @content;
  }
}

@mixin mobile-xxs {
  @media (max-width: $xxs-max) {
    @content;
  }
}

@mixin maxW($arg) {
  @media (max-width: $arg) {
    @content;
  }
}

@mixin minW($arg) {
  @media (min-width: $arg) {
    @content;
  }
}

@mixin largerDesktop {
  @media (min-width: $sm-min) {
    @content;
  }
}

@mixin smallerDesktop {
  @media (max-width: $sm-max) {
    @content;
  }
}

@mixin transform($arg) {
  transform: $arg;
  -webkit-transform: $arg;
  -o-transform: $arg;
}

@mixin transition($arg) {
  transition: $arg;
  -webkit-transition: $arg;
  -moz-transition: $arg;
  -o-transition: $arg;
}

@mixin text($font-size, $font-weight) {
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin textFull($font-size, $font-weight, $color) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}
