.input-text {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  input {
    width: 100%;
    border: 1px solid #c5c5c5;
    box-sizing: border-box;
    line-height: 20px;
    padding: 7px 13px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    outline: none;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    height: 50%;
    cursor: pointer;
  }
  &__title {
    width: 60%;
  }
}

.input-common {
  .custom-input {
    // height: 48px;
    border-radius: 6px;
    position: relative;
    padding-left: 16px;
    padding-top: 16px;
    border: 0.5px solid #c5c5c5;
    &:focus + span,
    &:not(:placeholder-shown) + span {
      top: 0;
      transform: unset;
      left: 16px;
      font-size: 12px;
      color: #3aa4f6;
    }
  }
  &__placeholder {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 48%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 24px;
    color: #7c808b;
    transition: 0.3s ease all;
  }
  &__help-text {
    pointer-events: none;
    color: #a0b4b6;
    font-size: 13px;
  }
}
