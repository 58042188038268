.ant-layout-sider-collapsed {
  p,
  h3 {
    opacity: 0;
    transition: all 200ms ease-out;
  }
}

.ant-layout-sider {
  .ant-menu-submenu-title,
  .ant-menu-item {
    padding-left: 16px !important;
  }
  // .ant-menu-item-only-child {
  //   padding-left: 32px !important;
  // }
  .ant-menu-inline.ant-menu-sub {
    padding-left: 16px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #dfdfdf;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #b9b9b9;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px;
}
