.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #000 !important;
  font-size: 14px;
}

.dropdown-form {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 14px;
    border-radius: 4px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
  }
  .ant-select-single .ant-select-selector {
    align-items: center;
  }
}
