.ant-upload.ant-upload-select-picture-card {
  width: 500px;
  height: 350px;
}

.player-wrapper {
  position: relative;
  margin-top: 5px;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
